<template>
  <div class="home" style="height:100%">
    <el-container style="height:100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main>
        <!-- <p>{{this.username}}</p> -->
        <h1 style="text-align: left; margin-left: 3rem; line-height: 1.5rem; padding: 0">用户信息</h1>
        <div class="userInfo">
          <div>
            <p>用户名：&nbsp;&nbsp;&nbsp; {{ this.username }}</p>
          </div>
          <div>
            <p>邮箱&nbsp;&nbsp;&nbsp;&nbsp;：&nbsp;&nbsp;&nbsp; {{ this.email }}</p>
          </div>
          <div>
            <el-button @click="resetPassword">重置密码</el-button>
          </div>
        </div>
      </el-main>

    </el-container>
    <FooterLayoutSlim></FooterLayoutSlim>
  </div>
</template>

<script>
import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayoutSlim from '../../components/FooterLayoutSlim'
import {getUser} from '@/api/user'

export default {
  name: 'User',
  components: {
    FooterLayoutSlim,
    HeaderLayout
  },
  data() {
    return {
      username: '',
      email: '',
    }
  },
  created() {
    if (localStorage.getItem('store')) {
      this.$store.commit('replace', JSON.parse(localStorage.getItem('store')))
    }
    this.get()

  },
  methods: {
    get() {
      getUser(this.$store.state.token).then(res => {
        // console.log(res)
        this.username = res.data.data.name
        this.email = res.data.data.email
      })
    },
    resetPassword() {
      this.$router.push('/resetpassword')
    }
  }
}
</script>

<style>

.el-header {
  background-color: #34495E;
  color: #333;
  text-align: center;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

.userInfo {
  background-color: #F0F8FF;
  text-align: left;
  margin: 50px;
  padding: 30px;
}
</style>
