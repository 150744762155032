import axios from 'axios'
import {USER_MODULE} from './_prefix'
import router from '../router'

export const getUser = (token) => {
  return axios.get(`${USER_MODULE}`,
    {
      headers: {
        Authorization: token
      }
    }
  ).then(
    res => {
      // console.log(res)
      return res
    }
  ).catch(function (error) {
    if (error.response) {
      if (error.response.data.code > 0 && error.response.data.code < 5) {
        router.push('/login')
      }
    }
  });
}
